<template>
    <div>
        <template v-if="loaderShow">
            <div class="wait">
                <Loader :loaderType="loaderType" />
            </div>
        </template>
        <div class="aboutus-page">
            <div class="display-image">
                <div class="img"></div>
            </div>

            <div class="aboutus-info">
                <h1 class="top-head">We Are Siloho</h1>
                <div class="info-tab">
                    <div class="head-info">
                        Designing a home doesn't have to be an expensive ordeal.
                        On the contrary, when done right, it saves money.
                        Converting a house into a well-designed and creatively
                        crafted home must be an honest, enjoyable, and visually
                        nourishing journey. As a company, we want nothing more
                        than to see our client's faces light up. And we
                        willingly go the extra many miles to make it happen.
                        Because we promise that when you trust us with your
                        home, we will turn it into your stunning cocoon. So how
                        about we get to know each other a little better?
                    </div>
                    <div class="about-info">
                        <div class="heading">About Us</div>
                        <div class="info">
                            We began our journey as a comprehensive home
                            furnishing brand to transforming into a one-stop
                            solution for all your home furnishing requirements.
                            SILOHO is Founded by Shan Gehlot and Co-founded by
                            Pranshu Sharma, both Engineers and Graduates of the
                            Indian Institute of Technology, Bombay. With
                            extensive experience in technology, real estate and
                            home furnishings, the partners at SILOHO employ
                            razor-edge technology to help clients envision and
                            furnish interior designs of their ideal homes within
                            a cost-effective budget. We have a strong team of
                            heads and hearts, working tirelessly to design homes
                            that reflect the inner you. We have also expanded
                            our scope of service with affordable interior
                            designs, customised visualization with 3D software
                            along with a full package with tailored costs and
                            administering the hand-over process for a smooth
                            transition.
                        </div>
                    </div>
                    <div class="our-story">
                        <div class="heading">Our Story</div>
                        <div class="info">
                            We are familiar with the beauty of spending twilight
                            evenings in a cosy living room or drawing an
                            imaginary plan for an ideal home office space. The
                            truth is, the desire for a beautiful home is deep
                            rooted in all of us and you deserve nothing less.
                            That's why we chose to make your life experiences
                            more delightful with stunning interiors and sublime
                            spaces. Whether it is an explosion of luxury and
                            extravagant settings or pastel interiors with
                            low-key surroundings, we will make it happen for
                            you. From minimal studio apartments to villas, we
                            enhance spaces with cost-effective interiors and
                            blooming happiness. One thing for sure: We promise
                            to put you at the heart of our decisions to deliver
                            to you the quality of life that you deserve.
                        </div>
                    </div>
                </div>
            </div>

            <div class="founder-info">
                <div class="founder-info-view">
                    <div class="head">
                        Our Founders
                    </div>
                    <div class="founder1">
                        <div class="founder1-image">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="pin"
                                style="fill:#606060"
                                width="140.484"
                                height="140.481"
                                viewBox="0 0 140.484 140.481"
                            >
                                <g
                                    id="Group_370"
                                    transform="translate(66.634 140.484) rotate(-135)"
                                >
                                    <path
                                        id="Path_701"
                                        fill="#e1e1e1"
                                        d="M53.709 79.2L5.279 30.768A18.024 18.024 0 1 1 30.768 5.279l56.076 56.077a25.235 25.235 0 1 1-35.687 35.688L28.919 75.266l1.4-1.4 1.812-1.812 1.624-1.624 22.5 21.516a18.024 18.024 0 1 0 25.494-25.492l-56.08-56.076a10.815 10.815 0 1 0-15.3 15.293L58.8 74.1a3.605 3.605 0 1 0 5.1-5.1L33.317 38.416l5.1-5.1L69 63.9a10.815 10.815 0 0 1-15.291 15.3z"
                                        transform="translate(0 0.004)"
                                    />
                                    <g
                                        id="Group_369"
                                        transform="translate(3.602 0)"
                                    >
                                        <g id="Group_372">
                                            <path
                                                id="Path_702"
                                                d="M28.57 48.6a10.649 10.649 0 0 0 1.117-.912 10.814 10.814 0 0 0 0-15.293L0 2.7 2.7 0l30.592 30.586a10.814 10.814 0 0 1 0 15.293A10.7 10.7 0 0 1 28.57 48.6z"
                                                class="cls-2"
                                                transform="translate(32.108 33.321)"
                                            />
                                            <path
                                                id="Path_703"
                                                d="M6.772.912a10.814 10.814 0 0 0 0 15.293L55.2 64.635a3.563 3.563 0 0 0 2.075 1.009 3.564 3.564 0 0 1-.582.793 3.6 3.6 0 0 1-5.1 0L3.167 18.008a10.814 10.814 0 0 1 0-15.293A10.7 10.7 0 0 1 7.889 0a10.647 10.647 0 0 0-1.117.912z"
                                                class="cls-2"
                                                transform="translate(0 9.47)"
                                            />
                                            <path
                                                id="Path_704"
                                                d="M24.528 27.422L3.49 6.224l2.6-2.6L28.133 25.62a18.011 18.011 0 0 0 19 4.142 18.024 18.024 0 0 1-22.607-2.339z"
                                                class="cls-2"
                                                transform="translate(24.523 66.328)"
                                            />
                                            <path
                                                id="Path_705"
                                                d="M78.69 97.046a25.132 25.132 0 0 1-8.52 5.607 25.147 25.147 0 0 0 4.924-39.492L19.01 7.086A18.024 18.024 0 0 0 0 2.94a18.024 18.024 0 0 1 22.614 2.343L78.69 61.361a25.233 25.233 0 0 1 0 35.685z"
                                                class="cls-2"
                                                transform="translate(4.552 0)"
                                            />
                                        </g>
                                    </g>
                                </g>
                            </svg>

                            <img
                                src="@/assets/images/founder1.png"
                                alt="Shan Gehlot"
                            />
                        </div>
                        <div class="details">
                            <a
                                class="name"
                                href="https://www.linkedin.com/in/shangehlot/"
                                target="_blank"
                            >
                                Shan Gehlot
                                <span class="linkedin-icon"
                                    ><font-awesome-icon
                                        :icon="['fab', 'linkedin']"
                                /></span>
                            </a>
                            <p class="position">Founder and CEO</p>
                            <p class="study">B.Tech (IIT Bombay)</p>
                        </div>
                        <img
                            v-if="windowWidth > 922"
                            src="@/assets/images/Ellipse.png"
                            class="circle-img"
                            alt=""
                        />
                    </div>
                    <div class="founder2">
                        <div class="founder2-image">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="pin"
                                style="fill:#606060"
                                width="140.484"
                                height="140.481"
                                viewBox="0 0 140.484 140.481"
                            >
                                <g
                                    id="Group_373"
                                    transform="translate(0 66.635) rotate(-45)"
                                >
                                    <path
                                        id="Path_701"
                                        fill="#e1e1e1"
                                        d="M53.709 25.237l-48.43 48.43a18.024 18.024 0 1 0 25.489 25.489l56.076-56.077A25.235 25.235 0 1 0 51.157 7.392L28.919 29.169l1.4 1.4 1.812 1.812 1.624 1.624 22.5-21.516a18.024 18.024 0 1 1 25.494 25.493l-56.08 56.075a10.815 10.815 0 1 1-15.295-15.293L58.8 30.334a3.605 3.605 0 1 1 5.1 5.1L33.317 66.02l5.1 5.1L69 40.532a10.815 10.815 0 0 0-15.291-15.295z"
                                        transform="translate(0 0)"
                                    />
                                    <g
                                        id="Group_369"
                                        transform="translate(3.602 1.786)"
                                    >
                                        <g
                                            id="Group_372"
                                            transform="translate(0)"
                                        >
                                            <path
                                                id="Path_702"
                                                d="M28.57 0a10.649 10.649 0 0 1 1.117.912 10.814 10.814 0 0 1 0 15.293L0 45.892l2.7 2.7 30.592-30.583a10.814 10.814 0 0 0 0-15.293A10.7 10.7 0 0 0 28.57 0z"
                                                class="cls-2"
                                                transform="translate(32.109 20.737)"
                                            />
                                            <path
                                                id="Path_703"
                                                d="M6.772 66.581a10.814 10.814 0 0 1 0-15.293L55.2 2.858a3.563 3.563 0 0 1 2.075-1.009 3.564 3.564 0 0 0-.582-.793 3.6 3.6 0 0 0-5.1 0L3.167 49.486a10.814 10.814 0 0 0 0 15.293 10.7 10.7 0 0 0 4.722 2.714 10.647 10.647 0 0 1-1.117-.912z"
                                                class="cls-2"
                                                transform="translate(0 25.69)"
                                            />
                                            <path
                                                id="Path_704"
                                                d="M21.038 5.278L0 26.476l2.6 2.6L24.643 7.081a18.011 18.011 0 0 1 19-4.142 18.024 18.024 0 0 0-22.607 2.34z"
                                                class="cls-2"
                                                transform="translate(28.013 3.625)"
                                            />
                                            <path
                                                id="Path_705"
                                                d="M78.69 5.607A25.132 25.132 0 0 0 70.17 0a25.147 25.147 0 0 1 4.924 39.492L19.01 95.568A18.024 18.024 0 0 1 0 99.713a18.024 18.024 0 0 0 22.615-2.343L78.69 41.293a25.233 25.233 0 0 0 0-35.686z"
                                                class="cls-2"
                                                transform="translate(4.552 0)"
                                            />
                                        </g>
                                    </g>
                                </g>
                            </svg>

                            <img
                                src="@/assets/images/founder2.png"
                                alt="Pranshu Sharma"
                            />
                        </div>
                        <div class="details">
                            <a
                                class="name"
                                href="https://www.linkedin.com/in/pranshu-sharma-1882614/"
                                target="_blank"
                            >
                                Pranshu Sharma
                                <span class="linkedin-icon"
                                    ><font-awesome-icon
                                        :icon="['fab', 'linkedin']"
                                /></span>
                            </a>
                            <p class="position">Co-Founder and CTO</p>
                            <p class="study">
                                MS (UMass, Amherst), <br />
                                B.Tech (IIT-Bombay)
                            </p>
                        </div>
                    </div>
                </div>
                <img
                    v-if="windowWidth < 922"
                    src="@/assets/images/Ellipse.png"
                    class="circle-img-mobile"
                    alt=""
                />
            </div>

            <Footer />
        </div>
    </div>
</template>

<script>
import Footer from 'componentsv2/Footer'
import Loader from 'componentsv2/Loader'
import { mapState } from 'vuex'
import loaderHandler from 'mixins/loader'

export default {
    name: 'AboutUsPage',
    mixins: [loaderHandler],
    components: {
        Footer,
        Loader,
    },
    mounted() {
        setTimeout(() => {
            this.loaderShow = false
        }, 1000)
    },
    computed: {
        ...mapState({
            windowWidth: state => state.AppModule.windowWidth,
        }),
    },
}
</script>

<style lang="scss">
@import 'AboutUsPage.scss';
</style>
